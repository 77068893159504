@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
    --radius: 0.5rem;
    --chart-1: 216, 100%, 60%;
    --chart-2: 152 82% 39%;
    --chart-3: 149, 70%, 44%;
    --chart-4: 42, 99%, 65%;
    --chart-5: 216, 100%, 75%;
    --chart-6: 216, 100%, 50%;
    --chart-7: 83, 87%, 76%;
    --chart-8: 184, 92%, 44%;
    --chart-9: 218, 85%, 33%;
    --chart-10: 220, 92%, 40%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer components {
  /* Swap default location of the clock icon on time inputs  */

  input[type='time']::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0px;
  }

  input[type='time'] {
    padding-inline-start: theme(spacing.8);
    position: relative;
  }
}

@import './defaults.css';
@import './utilities.css';

/*  
 * adding utility class for styling links like buttons where necessary
 * TODO: create additional variants
*/
@layer components {
  .btn.btn-primary {
    @apply ring-offset-background focus-visible:ring-ring bg-primary text-primary-foreground -mt-3 inline-flex h-10 items-center justify-center whitespace-nowrap rounded-md px-4 py-2 text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50;
  }
  .btn.btn-secondary {
    @apply ring-offset-background focus-visible:ring-ring border-input bg-background hover:bg-accent hover:text-accent-foreground mt-2 inline-flex h-10 items-center justify-center whitespace-nowrap rounded-md border px-4 py-2 text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 sm:mt-0;
  }
  .btn.btn-ghost {
    @apply ring-offset-background focus-visible:ring-ring hover:bg-accent hover:text-accent-foreground inline-flex h-10 items-center justify-center whitespace-nowrap rounded-md px-4 py-2 text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

/* Styles for Waymark Integration */

[cmdk-list-sizer]:has(.h-full) {
  height: 100%;
  overflow-y: hidden;
}

waymark-light-plugin-button::part(button) {
  /* Apply a custom smaller font size to the button */
  border-radius: theme('borderRadius.sm');
  text-transform: capitalize;
  background-color: white;
  color: theme('colors.gray.700');
  font-size: theme('fontSize.sm');
  font-family: inherit;
  padding-inline: theme('spacing.3');
  padding-block: theme('spacing.2');
  border: 1px solid theme('colors.gray.300');
}

waymark-light-plugin-button::part(logo) {
  /* Make the logo icon smaller */
  width: theme('size.5');
}

waymark-light-plugin-button::part(dialog) {
  /* Make the dialog a little smaller */
}
